<template>
  <resource-loader-controller
    :resource="resource"
    v-slot="{ receive, loading, result }"
  >
    <delayed-executor-controller
      :delay="300"
      :receiver="receive"
      v-slot="{ receive: delayedReceive }"
    >
      <view-component
        v-bind="$attrs"
        :value="value"
        @input="submit"
        :filter="filter"
        :result="result"
        :loading="loading"
        :receive="delayedReceive"
        :rules="rules"
        :initial-receive="initialReceive"
        :emit-full-value="emitFullValue"
      />
    </delayed-executor-controller>
  </resource-loader-controller>
</template>

<script>
import { createResource } from '@resource/resource'
import ViewComponent from './Component'

export default {
  name: 'filter-autocomplete-multiselect-widget-controller',
  props: ['value', 'filter', 'url', 'rules', 'initialReceive', 'emitFullValue'],

  components: {
    ViewComponent,
  },

  computed: {
    resource() {
      const { choiceUrl: url } = this.filter

      return createResource(`${url}{?query,page}`)
    },
  },

  methods: {
    submit(val) {
      this.$emit('input', val)
    },
  },
}

</script>
