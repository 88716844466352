import Cooperation from './Cooperation'
import Login from './Login'
import Register from './Register'
import BankId from './BankId'
import BankIdInfo from './BankIdInfo'

export default function install(Vue) {
  Vue.component('auth-cooperation', Cooperation)
  Vue.component('auth-login', Login)
  Vue.component('auth-register', Register)
  Vue.component('auth-bank-id', BankId)
  Vue.component('auth-bank-id-info', BankIdInfo)
}
