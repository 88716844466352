<template lang="pug">
  include /mixins.pug
  +b.ui-search
    +b.blur.is-active(
      v-if="isDesktop && isSearch"
      @click="hideSearch"
    )
    +e.icon
      +b.circle-button.--variant_1(@click.prevent="toggleSearch")
        +e.icon
          +b.I.icon-search
    +e.icon.--close(v-if="isDesktop && isSearch")
      +b.circle-button.--variant_1(@click.prevent="hideSearch")
        +e.icon
          +b.I.icon-close
    +e.wrapper(:class="{'is-active': isOpen || isDesktop && isSearch}")
      +e.close(
        v-if="!isDesktop"
        @click.prevent="hideSearch"
      )
        +b.I.icon-arrow-left
      +e.field
        +b.control-descriptor
          d-control-input(
            v-model='formdata.query',
            name='search'
            ref='search'
            type="text"
            autocomplete="off"
            :prepend-icon="isDesktop ? 'icon-search d-control-input__icon--size_xxs' : ''"
            :append-icon="!isDesktop ? 'icon-search d-control-input__icon--size_xxs' : ''"
            input-class="d-control-input--variant_3 d-control-input--variant_4"
            @keypress.13.prevent="openResultPage"
            @keydown.esc="hideSearch"
            @keydown.up.prevent="arrowUp"
            @keydown.down.prevent="arrowDown"
            @click="removeCurrentLinkIndex"
            @input="trimSearch();searchThrottleFn()"
            :placeholder="_('Введіть кадастровий номер або ID оголошення')"
          )
      +e.control
        +b.control-button(@click.prevent="trimSearch();searchThrottleFn()")
          +e.element
            +e.SPAN.text {{ _('Пошук') }}
      +e.content(v-if="isSearch")
        +e.part
          +e.results
            +e.item(v-for="(item, index) in items")
              +e.ROUTER-LINK.link(
                ref="searchLink"
                :class="{'is-active': index === currentLinkIndex}"
                :to="{name: 'profile:announcement:display', params: { id: item.id }}"
              ) {{ item.identifier }} - {{ item.cadastre }}
          +b.P.ds-caption.--size_3xs.--size_2xs-xl.--color_dark-grey(
            v-if="!items.length && !isLoading"
          ) {{ _("Результатов не найдено.") }}
          +b.i-preloader.--absolute(v-if="isLoading")
            +e.item.--size_md
</template>
<script>
import { throttle } from 'lodash'
import { announcementSearchResource } from '@api/announcement.service'

export default {
  name: 'UiSearch',

  props: {
    searchPageUrl: {},
  },

  data() {
    return {
      isOpen: false,
      formdata: {
        query: '',
      },
      items: [],
      isLoading: false,
      searchThrottleFn: null,
      currentLinkIndex: null,
      isDesktop: 1200 <= window.innerWidth,
    }
  },

  mounted() {
    const timeout = 1000
    this.searchThrottleFn = throttle(this.startSearch, timeout)
  },

  computed: {
    isSearch() {
      const minLength = 2
      return minLength <= this.formdata.query.length
    },
  },

  methods: {
    toggleSearch() {
      this.isOpen = !this.isOpen
      if (this.isOpen) this.setFocus()
    },

    hideSearch() {
      this.isOpen = false
      this.formdata.query = ''
      this.removeCurrentLinkIndex()
    },

    setFocus() {
      this.$nextTick(() => {
        if (this.isOpen) {
          this.$refs.search.$el.querySelector('input').focus()
        }
      })
    },

    trimSearch() {
      this.$nextTick(() => {
        this.formdata.query = this.formdata.query.trimStart().replace(/\s\s+/g, ' ')
      })
    },

    startSearch() {
      const minLength = 2
      this.items = []
      this.$nextTick(() => {
        const { query } = this.formdata
        if (query.length >= minLength) {
          const formdata = { query }
          this.isLoading = true
          announcementSearchResource.execute(formdata).then(res => {
            const { data: { items } } = res
            this.items = items
            this.isLoading = false
            this.highlightQuery()
          }).catch(() => {
            this.isLoading = false
          })
        }
      })
    },

    highlightQuery() {
      const timeout = 300
      setTimeout(() => {
        const { query } = this.formdata
        let links = this.$refs.searchLink
        if (links) {
          links = links.map(el => {
            const link = el.$el
            const regex = new RegExp(query, 'i')
            const match = link.innerHTML.match(regex)
            link.innerHTML = link.innerHTML.replace(/<\/?[^>]+(>|$)/g, '')
            if (match) {
              const [matched] = link.innerHTML.match(regex)
              link.innerHTML = link.innerHTML.replace(regex, `<mark>${matched}</mark>`)
            }
            return link
          })
        }
      }, timeout)
    },

    arrowUp() {
      const { length } = this.items
      const zero = 0

      if (null === this.currentLinkIndex) {
        this.currentLinkIndex = length
      }

      if (this.currentLinkIndex === zero) {
        this.currentLinkIndex = length
      }

      this.currentLinkIndex--
    },

    arrowDown() {
      const { length } = this.items
      const zero = 0
      const minusOne = -1

      if (null === this.currentLinkIndex) {
        this.currentLinkIndex = minusOne
      }

      this.currentLinkIndex++

      if (this.currentLinkIndex === length) {
        this.currentLinkIndex = zero
      }
    },

    removeCurrentLinkIndex() {
      this.currentLinkIndex = null
    },

    openResultPage() {
      const item = this.items[this.currentLinkIndex]
      const { id } = item
      this.$router.push({
        name: 'profile:announcement:display',
        params: { id },
      }).catch(() => {
        this.hideSearch()
      })
    },
  },
}
</script>
